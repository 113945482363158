import { css, html, TemplateResult, LitElement } from "lit";
import { customElement, property, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { SortOrder } from "se-shared/enums/sort-order";
import { ToasterService } from "se-shared/services/toaster.service";
import { DataGridColumn } from "../components/data-grid-template";
import "../components/data-grid.element";
import { BaseEditor } from "../editors/base-editor";

@customElement("se-parameter-editor")
export class ParameterEditorElement extends LitElement implements BaseEditor {
    @property() label: string;

    @state() private _data: any[] = [];

    private _columns: DataGridColumn[] = [];
    @state() private _lockHtml: TemplateResult;

    private _toasterService: ToasterService;

    @property({ attribute: false, type: Object }) properties: { [name: string]: string } = {};
    @property({ attribute: false, type: Object }) addedProperties: { [name: string]: string } = {};

    constructor() {
        super();

        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();

        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    save() {
        const value = {};

        for (const row of this._data) {
            if (row.encrypted) value["?" + row.name] = row.value;
            else value[row.name] = row.value;
        }
        this.properties = value;
    }

    reportValidity(): boolean {
        for (const row of this._data) {
            const errMsg = this.isNameError(row.name);
            if (errMsg) {
                this._toasterService.showError(errMsg);
                return false;
            } else if (this._data.some((p) => p !== row && p.name.toLowerCase() === row.name.toLowerCase())) {
                this._toasterService.showError(`Input parameter name ${row.name} already exists.`);
                return false;
            }
        }

        return true;
    }
    cancel() {
        this.loadData();
    }

    isNameError(name: string): string {
        if (!name) {
            return "Input parameter name cannot be empty.";
        }
        if (name.indexOf(":") >= 0) {
            return "Input parameter name cannot contain colons.";
        }
        if (name.indexOf("?") >= 0) {
            return "Input parameter name cannot contain questions marks.";
        }
        if (name.startsWith("_")) {
            return "Input parameter name cannot start with underscore.";
        }
        if (name.indexOf("\n") >= 0) {
            return "Input parameter name cannot contain line breaks.";
        }
        if (name.indexOf("\r") >= 0) {
            return "Input parameter name cannot contain line breaks.";
        }
        if (name.indexOf("\t") >= 0) {
            return "Input parameter name cannot contain tabs.";
        }
    }

    private async onNameChanged(newValue: string, row?: any, col?: DataGridColumn): Promise<{ success: boolean; errorMessage?: string }> {
        row[col.field] = newValue;
        const err = this.isNameError(newValue);
        if (err) {
            return { success: false, errorMessage: err };
        } else if (this._data.some((p) => p !== row && p.name.toLowerCase() === newValue.toLowerCase())) {
            return { success: false, errorMessage: `Input parameter name ${newValue} already exists.` };
        } else {
            this.dispatchEditorChanged();
            return { success: true };
        }
    }

    private async onEncryptedChanged(
        newValue: boolean,
        row?: any,
        col?: DataGridColumn
    ): Promise<{ success: boolean; errorMessage?: string }> {
        row.encrypted = newValue;
        this.requestUpdate();
        return { success: true };
    }

    private async loadData() {
        this._data = Object.entries({ ...this.properties, ...this.addedProperties }).map(([key, value]) => ({
            name: key[0] === "?" ? key.substr(1) : key,
            value: value,
            encrypted: key[0] === "?",
        }));
        this._columns = [
            {
                field: "name",
                title: "Name",
                sortable: true,
                editor: "text",
                setValue: this.onNameChanged.bind(this),
                cellStyle: { width: "40%" },
                getValue: (row) => (row.name[0] === "?" ? row.name.substr(1) : row.name),
                readonly: (row) => (row.encrypted ? "?" + row.name : row.name) in this.properties,
            },
            { field: "value", title: "Value", editor: (row) => (row.encrypted ? "password" : "text") },
            {
                field: "encrypted",
                title: "Encrypted",
                editor: "checkbox",
                align: "center",
                checkbox: true,
                setValue: this.onEncryptedChanged.bind(this),
                readonly: (row) => (row.encrypted ? "?" + row.name : row.name) in this.properties,
            },
            {
                isDelete: true,
                deleteRow: this.onDeleteRow.bind(this),
                readonly: (row) => (row.encrypted ? "?" + row.name : row.name) in this.properties,
            },
        ];
    }

    private onDeleteRow(): boolean {
        this.dispatchEditorChanged();
        return true;
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder == SortOrder.Ascending ? 1 : -1;
        this._data.sort((a, b) => (a[sortColumn] < b[sortColumn] ? -sortOrder : a[sortColumn] > b[sortColumn] ? sortOrder : 0));
    }

    private dispatchEditorChanged() {
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: {} }));
    }

    newProperty(): any {
        this.dispatchEditorChanged();
        return { name: "", value: "" };
    }

    render() {
        return html`
            <label>${this._lockHtml}${this.label}</label>
            <se-data-grid
                class="grid"
                .readonly=${this._lockHtml !== undefined}
                .rows=${this._data}
                .columns=${this._columns}
                @sortdata=${this.sortDataGrid}
                new-button="bottom"
                .newRow=${this.newProperty.bind(this)}
                layout="compact"
                save-mode="all"
            ></se-data-grid>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            gap: 5px;
            width: 100%;
        }
        label {
            font: var(--font-input);
        }
    `;
}
