import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { LitElement, html, css } from "lit";
import { customElement, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { InputEditorElement } from "../../elements/editors/input-editor.element";
import { SelectEditorElement } from "../../elements/editors/select-editor.element";
import { AuthService } from "../../services/auth.service";

@customElement("se-invite-user")
export class SeInviteUserElement extends LitElement {
    private _userService: UserService;
    private _toasterService: ToasterService;
    private _authService: AuthService;

    @query("#email") private _emailEditor: InputEditorElement;
    @query("#accessLevel") private _accessLevelEditor: SelectEditorElement;

    private _accessOptions = [];

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._userService = container.resolve(UserService);
        this._toasterService = container.resolve(ToasterService);
    }

    connectedCallback() {
        super.connectedCallback();
        this.initializeAccessOptions();
    }

    private initializeAccessOptions() {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return;
        }

        this._accessOptions = [
            { id: 300, name: "User" },
            { id: 200, name: "Administrator" },
        ];

        if (this._authService.isSE4Admin) {
            this._accessOptions.push({ id: 100, name: "SE4 Administrator" });
        }
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (!this._authService.isOrgAdmin) {
            document.location = "/login";
            return commands.prevent();
        }

        this.initializeAccessOptions();
    }

    private async sendInvite() {
        if (!this.reportValidity()) return;

        try {
            const result = await this._userService.api.inviteUserAsync({
                email: this._emailEditor.liveValue,
                accessLevel: Number(this._accessLevelEditor.liveValue)
            });

            if (result.isOk) {
                this._toasterService.showSuccess("Invitation sent successfully");
                Router.go('/org/users');
            } else {
                this._toasterService.showError(result.err.message || "Failed to send invitation");
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
        }
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }
    render() {
        return html`
            <form id="inviteForm" class="editor">
                <div class="content-container">
                    <div class="h3">Invite User</div>
                    <div class="scroll-container" style="display: flex;gap: 10px;flex-direction: column;">
                        <se-input-editor
                            id="email"
                            name="email"
                            type="text"
                            label="Email"
                            labelPosition="left"
                            input-type="email"
                            required
                            size="30"
                            isSettingsStyle
                        ></se-input-editor>

                        <se-select-editor
                            class="inputEditor"
                            id="accessLevel"
                            name="accessLevel"
                            required
                            label="Group"
                            .value="300"
                            labelPosition="left"
                            .options=${this._accessOptions}
                            width="100%"
                            isSettingsStyle
                        ></se-select-editor>
                    </div>
                </div>

                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.sendInvite()}"
                        action-delay="500"
                        text="Send Invitation"
                    ></se-primary-button>
                    <se-secondary-button 
                        @click="${() => Router.go('/org/users')}" 
                        text="Cancel"
                    ></se-secondary-button>
                </div>
            </form>
        `;
    }


    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            background: var(--color-light);
            padding: 10px;
            font-size: 12px;
            font-weight: 600;
            text-transform: uppercase;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 1000px;
            min-height: 0;
            overflow: hidden;
        }
        .content-container {
            border: 1px solid grey;
            border-radius: 5px;
            overflow: hidden;
        }
        .scroll-container {
            width: 500px;
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border: 1px 0px 1px 0px solid gray;
            box-shadow: 2px 2px 2px lightGray;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 4px;
        }
    `;
} 
