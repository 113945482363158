import { container, singleton } from "tsyringe";
import { idName } from "../models/id-name";
import { Organization } from "../models/organization";
import { OrganizationEmailSettingsModel } from "../models/organization-emails-model";
import { OrganizationUsersViewModel } from "../models/organization-users-view-model";
import { OrganizationsViewModel } from "../models/organizations-view-model";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";
import { OrganizationViewModel } from "../models/organization-view-model";

@singleton()
export class OrganizationApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
    }

    async getAsync(id: number) {
        return this.getObjectAsync<OrganizationViewModel>(`/api/organization/${id}`, this._authService.token);
    }
    async getAllAsync() {
        return this.getObjectAsync<Organization[]>(`/api/organization`, this._authService.token);
    }
    async getAllDetailsAsync(organizationRequest: any) {
        return this.postObjectReturnObjectAsync<OrganizationsViewModel>(
            `/api/organization/search`,
            organizationRequest,
            this._authService.token
        );
    }
    async getAllIdAndNamesAsync() {
        return this.getObjectAsync<idName[]>(`/api/organization/id-name`, this._authService.token);
    }
    async createAsync(
        name: string,
        isBillable: boolean,
        isMfaRequired: boolean,
        isAuditLog: boolean,
        isProxyRequired: boolean,
        isAllowDevTools: boolean
    ) {
        return this.postObjectAsync(
            `/api/organization`,
            {
                name: name,
                isBillable: isBillable,
                isMfaRequired: isMfaRequired,
                isAuditLog: isAuditLog,
                isProxyRequired: isProxyRequired,
                isAllowDevTools: isAllowDevTools,
            },
            this._authService.token
        );
    }
    async updateAsync(
        id: number,
        name: string,
        isBillable: boolean,
        isMfaRequired: boolean,
        isAuditLog: boolean,
        isProxyRequired: boolean,
        isAllowDevTools: boolean
    ) {
        return this.putObjectAsync(
            `/api/organization/${id}`,
            {
                name: name,
                isBillable: isBillable,
                isMfaRequired: isMfaRequired,
                isAuditLog: isAuditLog,
                isProxyRequired: isProxyRequired,
                isAllowDevTools: isAllowDevTools,
            },
            this._authService.token
        );
    }
    async approveOrgAsync(approvalString: string) {
        return this.putObjectAsync(`/api/user/mail-approve`, approvalString, this._authService.token);
    }

    async deleteAllAsync(orgIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/organization/all`, { ids: orgIds }, this._authService.token);
    }

    async deleteAsync(id: number) {
        return this.deleteObjectAsync(`/api/organization/${id}`, this._authService.token);
    }

    async approveOrgAdminsAsync(id: number) {
        return this.putAsync(`/api/organization/${id}/approve`, this._authService.token);
    }

    async getAllOrganizationUsersAsync(pageIndex = 1, recordsPerPage = 100, sortColumn = "", sortOrder = -1) {
        return this.getObjectAsync<OrganizationUsersViewModel>(
            `/api/organization/users?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            this._authService.token
        );
    }

    async getAllOrganizationUserEmailsAsync() {
        return this.getObjectAsync<{ name: string; email: string }[]>(`/api/organization/users/names-emails`, this._authService.token);
    }

    async getOrganizationEmailSettingsAsync(orgId: number) {
        return this.getObjectAsync<OrganizationEmailSettingsModel>(`/api/organization/email-settings/${orgId}`, this._authService.token);
    }
    async updateOrganizationEmailSettingsAsync(emailSettings: OrganizationEmailSettingsModel) {
        return this.postObjectAsync(
            `/api/organization/email-settings/${emailSettings.organizationId}`,
            emailSettings,
            this._authService.token
        );
    }

    /**
     * Add credits to an organization
     */
    async addCreditsAsync(organizationId: number, amount: number) {
        return this.postObjectAsync(
            `/api/organization/${organizationId}/credits`,
            amount,
            this._authService.token
        );
    }
}
