import { PreventAndRedirectCommands, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { Organization } from "../../models/organization";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { BaseEditor } from "../editors/base-editor";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-organization-editor")
export class SeOrganizationEditorElement extends LitElement {
    private _authService: AuthService;
    private _organizationService: OrganizationService;
    private _toasterService: ToasterService;
    private _isNew = false;
    private _organization?: OrganizationViewModel;

    @property() organization?: OrganizationViewModel;

    @state() private _isLoading = true;

    @query("#name") private _nameEditor: InputEditorElement;
    @query("#isBillable") private _billableEditor: CheckboxEditorElement;
    @query("#isMfaRequired") private _isMfaRequiredEditor: CheckboxEditorElement;
    @query("#isProxyRequired") private _isProxyRequiredEditor: CheckboxEditorElement;
    @query("#isAllowDevTools") private _isAllowDevToolsEditor: CheckboxEditorElement;
    @query("#isAuditLog") private _isAuditLogEditor: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._organizationService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async loadData() {
        if (this.organization) {
            this._isNew = false;
            const res = await this._organizationService.api.getAsync(this.organization.id);
            if (res.isOk) {
                this._organization = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        } else {
            this._isNew = true;
            this._organization = new OrganizationViewModel();
        }
        this._isLoading = false;
    }

    reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as unknown as BaseEditor)?.reportValidity?.() === false) return false;
        }
        return true;
    }

    async saveAsync() {
        if (this.reportValidity()) {
            const res = this._isNew
                ? await this._organizationService.api.createAsync(
                      this._nameEditor.liveValue,
                      this._billableEditor.liveValue,
                      this._isMfaRequiredEditor.liveValue,
                      this._isAuditLogEditor.liveValue,
                      this._isProxyRequiredEditor.liveValue,
                      this._isAllowDevToolsEditor.liveValue
                  )
                : await this._organizationService.api.updateAsync(
                      this.organization.id,
                      this._nameEditor.liveValue,
                      this._billableEditor.liveValue,
                      this._isMfaRequiredEditor.liveValue,
                      this._isAuditLogEditor.liveValue,
                      this._isProxyRequiredEditor.liveValue,
                      this._isAllowDevToolsEditor.liveValue
                  );
            if (res.isOk) {
                this._toasterService.showSuccess("Organization saved successfully.");
                this.close(true);
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
    }

    close(isSave: boolean) {
        this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true, detail: { isSave: isSave } }));
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">${this._isNew ? `Add Organization` : `Edit ${this.organization.name}`}</div>                
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{
                        boxShadow: "2px 2px 2px lightGray",
                        border: "1px solid gray",
                        borderRadius: "5px 5px",
                        backgroundColor: "white",
                        minHeight: "50px",
                        minWidth: "125px",
                    }}
                    .isLoading=${this._isLoading}
                >
                <div class="scroll-container">
                    <se-input-editor
                        id="name"
                        name="name"
                        type="text"
                        label="Name"
                        labelPosition="top"
                        input-type="text"
                        required
                        size="30"
                        .value=${this._organization?.name}
                    ></se-input-editor>
                    <div style="padding:10px 0px 10px 0px">
                        <se-checkbox-editor
                            id="isMfaRequired"
                            width="300px"
                            .value="${this._organization?.isMfaRequired ?? false}"
                            name="isMfaRequired"
                            label="Multi-factor authentication required"
                            labelPosition="right"
                        ></se-checkbox-editor>
                    </div>
                    ${
                        this._authService.isSE4Admin
                            ? html` <div style="padding:10px 0px 10px 0px">
                                      <se-checkbox-editor
                                          id="isAuditLog"
                                          width="300px"
                                          .value="${this._organization?.isAuditLog ?? false}"
                                          name="isMfaRequired"
                                          label="Audit log enabled"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                  </div>
                                  <div style="padding:10px 0px 10px 0px">
                                      <se-checkbox-editor
                                          id="isProxyRequired"
                                          width="300px"
                                          .value="${this._organization?.isProxyRequired ?? true}"
                                          name="isMfaRequired"
                                          label="Proxy required"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                  </div>
                                  <div style="padding:10px 0px 10px 0px">
                                      <se-checkbox-editor
                                          id="isAllowDevTools"
                                          width="300px"
                                          .value="${this._organization?.isAllowDevTools ?? false}"
                                          label="Allow DevTools"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                  </div>
                                  <div style="padding:10px 0px 10px 0px">
                                      <se-checkbox-editor
                                          id="isBillable"
                                          width="300px"
                                          .value="${this._organization?.isBillable ?? true}"
                                          name="Billable"
                                          label="Billable"
                                          labelPosition="right"
                                      ></se-checkbox-editor>
                                  </div>`
                            : html``
                    }
                </div>
                <div class="savePanel">
                    <se-primary-button
                        .action="${() => this.saveAsync()}"
                        action-delay="500"
                        text="${this._isNew ? "Add" : "Save"} Organization"
                    ></se-primary-button>
                    <se-secondary-button @click="${() => this.close(false)}" text="Cancel"></se-secondary-button>
                </div>
                </<se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 7px;
        }
    `;
}
