import { css, LitElement, html } from "lit";
import { query, customElement, state, property } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { container } from "tsyringe";
import { MenuService } from "../../services/menu.service";
import "./fa-icon.element";

@customElement("se-context-menu")
export class ContextMenuElement extends LitElement {
    private _menuService: MenuService;

    @state() private subMenu: any;

    @state() private menu: MenuItem[] = null;
    //@state() menuButton: HTMLElement;
    private tag: any;
    @state() private _isSubFoldRight = true;

    private currentMenuItem?: HTMLLIElement;
    private currentSubMenuItem?: HTMLLIElement;

    @query(".menu") private menuContainer: HTMLUListElement;
    @query(".sub-menu") private subMenuContainer: HTMLUListElement;

    private openTimeout: NodeJS.Timeout;
    private closeTimeout: NodeJS.Timeout;

    constructor() {
        super();
        this._menuService = container.resolve(MenuService);
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("resize", () => this.resize());
    }

    disconnectedCallback() {
        window.removeEventListener("resize", () => this.resize());
        super.disconnectedCallback();
    }

    private resize() {
        this._menuService.closeMenu();
    }

    static styles = css`
        :host {
            font: var(--font);
            box-sizing: border-box;
        }
        a {
            white-space: nowrap;
            text-decoration: none;
            color: black;
        }
        ul.sub-menu {
            position: absolute;
            z-index: 1000;
        }
        ul.menu,
        ul.sub-menu {
            padding: 0;
            margin: 0;
            padding-top: 3px;
            padding-bottom: 3px;
            display: flex;
            flex-direction: column;
            list-style: none;
            text-decoration: none;
            background-color: var(--color-light);
            box-shadow: 1px 1px 1px gray;
            border-radius: 2px 2px 2px 2px;
        }
        .menu-item,
        .sub-menu-item {
            padding-left: 10px;
            padding-right: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
        }
        li.sep {
            padding: 0;
            margin: 0;
            font-size: 0.2em;
        }
        li.menu-item-selected {
            background-color: var(--color-secondary);
        }
        li.menu-item-selected > a.menu-link {
            color: white;
        }
        li a.menu-link {
            text-decoration: none;
            color: black;
            margin-right: 10px;
        }
        li.menu-item:not([disabled]):hover {
            background-color: var(--color-secondary);
        }
        li.menu-item:not([disabled]):hover > a.menu-link {
            color: white;
        }
        li.menu-item:not([disabled]):hover > fa-icon.icon {
            color: white;
        }
        li.menu-item fa-icon.icon {
            color: var(--color-lightblue);
        }
        .menu-item[disabled] {
            opacity: 0.7;
            color: black;
            cursor: default;
        }
        .menu-item[disabled] a {
            color: black !important;
        }
        .shortCutText {
            color: gray;
            text-align: right;
        }
    `;

    private hasSeparator(array: MenuItem[], index: number) {
        if (index > 0 && array[index - 1].text === "-") {
            index--;
            while (index > 0 && array[index - 1].text === "-") index--;
            if (index === 0) return false;
            else return true;
        } else return false;
    }

    render() {
        return html`
            <ul class="menu" id="menu" @mouseleave=${this.menuItemMouseLeave} @mousedown=${(e: MouseEvent) => e.stopPropagation()}>
                ${this.menu &&
                this.menu
                    .filter((p) => !p.hidden)
                    .map((menuitem: MenuItem, index, array) => {
                        const pointer = { cursor: menuitem.menu || menuitem.disabled ? "default" : "pointer" };
                        return html` ${menuitem.text === "-"
                            ? html``
                            : html` ${this.hasSeparator(array, index)
                                      ? html`<li class="sep"><hr style="width:85%; opacity: 0.5" /></li>`
                                      : html``}

                                  <li
                                      style=${styleMap(pointer)}
                                      class="menu-item"
                                      .menuItem=${menuitem}
                                      @mouseenter=${this.subMenuItemMouseEnter}
                                      @mouseleave=${this.subMenuItemMouseLeave}
                                      @mousedown=${this.menuItemMouseDown}
                                      ?disabled=${menuitem.disabled}
                                  >
                                      ${menuitem.icon
                                          ? html`<fa-icon class="icon" ?defaultColors=${true} fa-class="fal ${menuitem.icon}"></fa-icon>`
                                          : html``}
                                      <a style=${styleMap(pointer)} href="javascript:void(0);" class="menu-link">${menuitem.text}</a>

                                      ${menuitem.menu
                                          ? html`<span style="float:right;width:16px;"
                                                >&nbsp;<fa-icon
                                                    class="icon"
                                                    single-color="gray"
                                                    fa-class="fas ${this._isSubFoldRight || this.subMenu !== menuitem.menu
                                                        ? "fa-caret-right"
                                                        : "fa-caret-left"}"
                                                ></fa-icon
                                            ></span>`
                                          : html``}
                                      ${this.subMenu && this.subMenu === menuitem.menu
                                          ? html` <ul class="sub-menu">
                                                ${this.subMenu
                                                    .filter((p) => !p.hidden)
                                                    .map((subMenuItem: MenuItem, index, array) => {
                                                        const subPointer = { cursor: subMenuItem.disabled ? "default" : "pointer" };
                                                        return html` ${subMenuItem.text === "-"
                                                            ? html``
                                                            : html` ${this.hasSeparator(array, index)
                                                                      ? html`<li class="sep"><hr style="width:85%; opacity: 0.5" /></li>`
                                                                      : html``}
                                                                  <li
                                                                      style=${styleMap(subPointer)}
                                                                      class="menu-item"
                                                                      .menuItem=${subMenuItem}
                                                                      @mousedown=${this.menuItemMouseDown}
                                                                      ?disabled=${subMenuItem.disabled}
                                                                  >
                                                                      ${subMenuItem.icon
                                                                          ? html`<fa-icon
                                                                                class="icon"
                                                                                ?defaultColors=${true}
                                                                                fa-class="fal ${subMenuItem.icon}"
                                                                            ></fa-icon>`
                                                                          : html``}
                                                                      <a
                                                                          style=${styleMap(subPointer)}
                                                                          href="javascript:void(0);"
                                                                          class="menu-link"
                                                                          >${subMenuItem.text}</a
                                                                      >
                                                                  </li>`}`;
                                                    })}
                                            </ul>`
                                          : html``}
                                  </li>`}`;
                    })}
            </ul>
        `;
    }

    async showMenu(options: { menu: MenuItem[]; tag?: any }) {
        this.menu = options.menu;
        //this.menuButton = options.button;
        this.tag = options.tag;
        this.requestUpdate();
        await this.updateComplete;

        /*if (this.menuContainer && this.menuButton) {
            const rect = this.menuButton.getBoundingClientRect();
            if (rect.left === 0 && rect.bottom === 0) {
                //Seems to be a render timing issue at startup.
                setTimeout(() => this.setMenuContainerPos, 1);
            }
            else {                
                if (rect.left + this.menuContainer.clientWidth < window.innerWidth - 10) {
                    this.menuContainer.style.left = `${rect.left}px`;
                    this._isSubFoldRight = true;
                }
                else {
                    this.menuContainer.style.left = `${rect.right - this.menuContainer.clientWidth}px`;
                    this._isSubFoldRight = false;
                }
                if (rect.bottom + this.menuContainer.clientHeight < window.innerHeight - 10) {
                    this.menuContainer.style.top = `${rect.bottom}px`;                    
                }
                else if (rect.top - this.menuContainer.clientHeight > 10) {
                    this.menuContainer.style.top = `${rect.top - this.menuContainer.clientHeight}px`;                    
                }
                else {
                    this.menuContainer.style.top = '5px';
                    if (this._isSubFoldRight) {
                        this.menuContainer.style.left = `${rect.right}px`;
                    }
                    else {
                        this.menuContainer.style.left = `${rect.left - this.menuContainer.clientWidth}px`;
                    }
                }                         
            }                
        }*/
    }

    /*private setMenuContainerPos() {
        const { left: x, bottom: y } = this.menuButton.getBoundingClientRect();
        this.menuContainer.style.left = `${x}px`;
        this.menuContainer.style.top = `${y}px`;
    }*/

    private menuItemMouseDown(event: MouseEvent) {
        const elem = event.currentTarget as any;
        if (elem.menuItem) {
            const menu = elem.menuItem as MenuItem;
            if (!menu.menu && !menu.disabled) {
                if (menu.action) {
                    menu.action();
                } else if (menu.url) {
                    window.location.href = menu.url;
                } else {
                    const evt = new CustomEvent("menuclick", {
                        bubbles: true,
                        composed: true,
                        detail: { target: elem, menu: menu, tag: this.tag, clientX: event.clientX, clientY: event.clientY },
                    });
                    this.dispatchEvent(evt);
                }
                this._menuService.closeMenu();
            }
        }
    }

    private menuItemMouseLeave(event: MouseEvent) {
        if (this.menuContainer === event.currentTarget) {
            const rect1 = this.menuContainer.getBoundingClientRect();
            if (
                (event.x < rect1.left && this._isSubFoldRight) ||
                (event.x > rect1.right && !this._isSubFoldRight) ||
                event.y < rect1.top ||
                rect1.y > rect1.bottom
            ) {
                if (this.currentMenuItem) {
                    this.currentMenuItem.classList.remove("menu-item-selected");
                    this.currentMenuItem = null;
                }
            }
        }
    }

    private subMenuItemMouseLeave(event: MouseEvent) {
        if (this.currentSubMenuItem === event.currentTarget) {
            const rect1 = this.subMenuContainer.getBoundingClientRect();
            if ((event.x < rect1.left && this._isSubFoldRight) || (event.x > rect1.right && !this._isSubFoldRight)) {
                if (this.openTimeout) {
                    clearTimeout(this.openTimeout);
                    this.openTimeout = null;
                }
                if (!this.closeTimeout) this.closeTimeout = global.setTimeout(() => this.closeSubMenu(), 300);
            }
        }
    }

    private subMenuItemDividerMouseEnter(event: MouseEvent) {
        if (this.currentMenuItem !== event.currentTarget) {
            if (this.currentMenuItem) this.currentMenuItem.classList.remove("menu-item-selected");
            this.currentMenuItem = event.currentTarget as HTMLLIElement;
        }
    }

    private subMenuItemMouseEnter(event: MouseEvent) {
        if (this.currentMenuItem !== event.currentTarget) {
            if (this.currentMenuItem) this.currentMenuItem.classList.remove("menu-item-selected");
            this.currentMenuItem = event.currentTarget as HTMLLIElement;
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
                this.closeTimeout = null;
            }
            if (!this.openTimeout) this.openTimeout = global.setTimeout(() => this.showSubMenu(), 300);
            //this.showSubMenu();
        }
    }

    private async showSubMenu() {
        this.openTimeout = null;
        if (this.currentMenuItem) {
            const subMenuItem = (this.currentMenuItem as any).menuItem as MenuItem;

            if (subMenuItem) {
                if (subMenuItem.menu) {
                    this.closeSubMenu();

                    if (!subMenuItem.disabled) {
                        this.subMenu = subMenuItem.menu;
                        this.currentSubMenuItem = this.currentMenuItem;
                        this.currentSubMenuItem.classList.add("menu-item-selected");
                        this.requestUpdate();
                        await this.updateComplete;

                        const rect1 = this.currentSubMenuItem.getBoundingClientRect();
                        if (rect1.right + this.subMenuContainer.clientWidth < window.innerWidth - 10) {
                            this.subMenuContainer.style.left = `${rect1.right - rect1.left + 1}px`;
                            this._isSubFoldRight = true;
                        } else {
                            this.subMenuContainer.style.left = `${-this.subMenuContainer.clientWidth - 1}px`;
                            this._isSubFoldRight = false;
                        }
                        if (rect1.top + this.subMenuContainer.clientHeight < window.innerHeight - 10) {
                            this.subMenuContainer.style.top = `${this.currentSubMenuItem.offsetTop}px`;
                        } else {
                            this.subMenuContainer.style.top = `${
                                this.currentSubMenuItem.offsetTop + this.currentSubMenuItem.clientWidth - this.subMenuContainer.clientHeight
                            }px`;
                        }
                    }
                } else {
                    if (this.currentSubMenuItem) {
                        this.closeSubMenu();
                    }
                }
            }
        }
    }

    closeMenu() {
        /*if (this.menu) {
            this.menu = null;                        
            this.currentMenuItem = undefined;
            if (this.hoverClass)
                this.menuButton.classList.remove(this.hoverClass);
            this.menuButton = undefined;            
        }*/
        this._menuService.closeMenu();
    }

    private closeSubMenu() {
        this.subMenu = null;
        this.currentSubMenuItem = undefined;
        if (this.openTimeout) {
            clearTimeout(this.openTimeout);
            this.openTimeout = null;
        }
        if (this.closeTimeout) {
            clearTimeout(this.closeTimeout);
            this.closeTimeout = null;
        }
    }

    isOpen() {
        return this.menu !== null;
    }
}

export interface MenuItem {
    text: string;
    name?: string;
    icon?: string;
    shortCutText?: string;
    checked?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    url?: string;
    action?: Function;
    menu?: MenuItem[];
}
