import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { AppSettings } from "../../models/app-settings";
import "../components/side-nav.element"
import { SeUserEditorElement } from "../users/user-editor.element";

@customElement("se-organization-settings")
export class SeOrganizationSettingsElement extends LitElement {
    private _authService: AuthService;
    private _userState: UserState;
    private _modalService: ModalDialogService;
    private _menuService: MenuService;
    private _settings: AppSettings;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _currentSlot: string = 'users';
    @state() private _showInvite = false;
    @state() private _menuItems = [];
    @state() private _titleOverride = '';

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);

        this._settings = container.resolve(AppSettings);
        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -4;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._initializeMenuItems();
        
        // Listen for route changes
        window.addEventListener('vaadin-router-location-changed', () => this._handleRouteChange());
    }

    disconnectedCallback() {
        super.disconnectedCallback();
        window.removeEventListener('vaadin-router-location-changed', () => this._handleRouteChange());
    }

    private async _handleRouteChange() {
        const pathParts = window.location.pathname.split('/');
        
        if (pathParts.includes('api-keys')) {
            const userId = pathParts[pathParts.indexOf('api-keys') + 1];
            await this._updateApiKeysTitle(userId);
        } else {
            // Reset the title for other routes
            this._titleOverride = '';
        }
    }

    private async _updateApiKeysTitle(userId: string) {
        const result = await this._userService.api.getAsync(parseInt(userId));
        if (result.isOk) {
            this._titleOverride = `${result.value.name}'s API Keys`;
        }
    }

    public async onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ){
        if (!this._authService.isOrgAdmin) {
            return commands.redirect("/login");
        }

        const pathParts = location.pathname.split('/');
        
        if (pathParts.includes('api-keys')) {
            const userId = pathParts[pathParts.indexOf('api-keys') + 1];
            await this._updateApiKeysTitle(userId);
        } else {
            this._titleOverride = '';
        }
    }

    private _handleNavChange(e: CustomEvent) {
        this._currentSlot = e.detail.slotName;
    }

    private _initializeMenuItems() {
        const baseItems = [
            { label: "Users", path: "users" },
            { label: "Proxy Pools", path: "proxies" },
            { label: "Destinations", path: "destinations" },
            { label: "Transactions", path: "transactions" }
        ];

        const adminItems = this._authService.isOrgAdmin ? [
            { label: "Audit Logs", path: "account-logs" },
            { label: "Audit Agents", path: "agent-logs" },
            { label: "Audit Runs", path: "run-logs" },
            { label: "Audit Logins", path: "login-logs" }
        ] : [];

        this._menuItems = [...baseItems, ...adminItems];
    }

    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a>${this._authService.user.organizationName}</a>
                    </div>
                </div>
                <se-nav 
                    baseUrl="/org" 
                    .menuItems=${this._menuItems}
                    .titleOverride=${this._titleOverride}
                >
                    <slot></slot>
                </se-nav>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .header {
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
            margin-bottom: 10px;
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
        }
    `;
}

