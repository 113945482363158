import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router } from "@vaadin/router";
import { ToasterService } from "se-shared/services/toaster.service";
import { UserService } from "../../services/user.service";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import logo from "../../../../assets/logo-tagline.svg";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import twitter from "../../../../assets/twitter.svg";

@customElement("se-pending-verification")
export class PendingVerificationElement extends LitElement {
    private _userService = container.resolve(UserService);
    private _toasterService = container.resolve(ToasterService);
    @state() private _email: string;
    @state() private _isProcessing = false;

    constructor() {
        super();
        // Get email from session storage that was saved during registration
        this._email = sessionStorage.getItem('pendingVerificationEmail');
    }

    private async resendVerification() {
        if (!this._email || this._isProcessing) {
            return;
        }

        this._isProcessing = true;
        try {
            const result = await this._userService.api.resendRegistrationVerificationAsync({
                email: this._email
            });

            if (result.isOk) {
                this._toasterService.showSuccess("Verification email has been resent");
            } else {
                this._toasterService.showError(result.err.message || "Failed to resend verification email");
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
        } finally {
            this._isProcessing = false;
        }
    }

    private goToLogin() {
        Router.go("/login");
    }

    private submitForm(ev: KeyboardEvent) {
        if (ev.key === 'Enter') {
            ev.preventDefault();
            this.resendVerification();
        }
    }

    disconnectedCallback() {
        sessionStorage.removeItem('pendingVerificationEmail');
        super.disconnectedCallback();
    }

    firstUpdated() {
        if (!this._email) {
            Router.go('/login');
        }
    }

    render() {
        return html`
            <div class="header"></div>
            <form id="verificationForm" class="editor" @keydown="${this.submitForm}">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <div class="h2">Verify Your Email</div>
                        <div class="message">
                            We've sent a verification email to <strong>${this._email}</strong>. 
                            Please check your inbox and click the verification link to complete your registration.
                        </div>
                        <div class="actions">
                            <se-primary-button
                                size="normal"
                                position="first"
                                @click="${() => this.resendVerification()}"
                                text="Resend Verification Email"
                                class="inputEditor"
                                ?disabled="${this._isProcessing}"
                                ?loading="${this._isProcessing}"
                            ></se-primary-button>
                            <div class="loginLink">
                                <a href="#" class="link" @click="${() => this.goToLogin()}">Return to Login</a>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div class="footer">
                <div>
                    © 2024 Sequentum • 
                    <a href="https://www.sequentum.com/privacy-policy" target="_blank">Privacy Policy</a> • 
                    <a href="https://www.sequentum.com/terms-of-service" target="_blank">Terms of Service</a>
                </div>
                <div style="text-align: right; flex:1">
                    <a href="https://twitter.com/sequentuminc" target="_blank"><img src=${twitter} /></a>
                    <a href="https://www.linkedin.com/company/sequentum/" target="_blank"><img src=${linkedin} /></a>
                    <a href="https://www.instagram.com/sequentuminc/" target="_blank"><img src=${instagram} /></a>
                </div>
            </div>
        `;
    }

    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .h2 {
            font: var(--font-h2);
            color: var(--color-primary);
            font-weight: 600;
            padding: 0px 0px 10px;
        }
        .message {
            margin: 20px 0;
            line-height: 1.5;
        }
        .actions {
            margin-top: 20px;
        }
        .loginLink {
            text-align: center;
            margin-top: 20px;
        }
        .link {
            color: var(--color-purple-dark);
            text-decoration: none;
        }
        .footer {
            color: rgba(83, 86, 122, 1);
            font: var(--font-small);
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
        }
        .footer a {
            color: rgba(83, 86, 122, 1);
            text-decoration: none;
        }
        .footer a:hover {
            color: var(--color-secondary);
        }
    `;
}
