import { LitElement, html, css } from 'lit';
import { customElement, property, query, state } from 'lit/decorators.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

@customElement('se-toggle')
export class SeToggle extends LitElement {
    @property({ type: Boolean }) value: boolean;
    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    oldValue?: boolean;
    @state() _liveValue: boolean;
    @state() liveValue?: boolean;
    @query('#toggle') private _editorElement: HTMLInputElement;   

    connectedCallback() {
        super.connectedCallback();
        this._liveValue = this.value;
    }
    constructor() {
        super();
    }    
    firstUpdated() {
    }

    onMouseDown() {
        switch (this.liveValue) {
            case true:
                this.liveValue = false;
                break;
            case false:
                this.liveValue = true;
                break;
        }
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent("valueChanged", { bubbles: true, composed: true, detail: { editor: this, value: this.liveValue, oldValue: this.value } }));
        }, 500);    }

    render() {
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "column") ||
            (this.labelPosition === "bottom" && "row");
        const justifyContent = this.labelPosition === "left" ? "space-between" : "flex-start";
        
        return html`
            <div class="toggle-container" style="flex-direction: ${flexDirection}; justify-content: ${justifyContent}">
                <div class="toggle-switch" @mousedown="${this.onMouseDown}">
                    ${this.value ?
                        html`<input checked type="checkbox" id="toggle" class="toggle-input">` :
                        html`<input type="checkbox" id="toggle" class="toggle-input">`}
                    <label for="toggle" class="toggle-label"></label>
                </div>
                ${this.label ? html`<label class="label-text">${this.label}</label>` : ''}
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            width: 100%;
        }
        :host * {
            box-sizing: border-box;
        }

        .toggle-switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .toggle-input {
            display: none;
        }

        .toggle-label {
            position: absolute;
            top: 0;
            left: 0;
            width: 60px;
            height: 34px;
            border-radius: 17px;
            background-color: #ccc;
            cursor: pointer;
            transition: background-color 0.4s ease;
        }

        .toggle-label:before {
            content: '';
            position: absolute;
            top: 3px;
            left: 3px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: #fff;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
            transition: transform 0.4s ease;
        }

        .toggle-input:checked + .toggle-label {
            background-color: var(--color-status-blue);
        }

        .toggle-input:checked + .toggle-label:before {
            transform: translateX(26px);
        }

        .toggle-container {
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            min-height: 34px;
        }

        .label-text {
            font: var(--font-input-label);
            color: var(--color-text);
        }
    `;
}