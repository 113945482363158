import { PreventAndRedirectCommands, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { OrganizationViewModel } from "../../models/organization-view-model";
import { AuthService } from "../../services/auth.service";
import { OrganizationService } from "../../services/organization.service";
import { InputEditorElement } from "../editors/input-editor.element";

@customElement("se-organization-credits-editor")
export class SeOrganizationCreditsElement extends LitElement {
    private _authService: AuthService;
    private _organizationService: OrganizationService;
    private _toasterService: ToasterService;

    @state() organization?: OrganizationViewModel;
    @state() private _isLoading = true;
    @query("#creditAmount") private _creditAmountEditor: InputEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._organizationService = container.resolve(OrganizationService);
        this._toasterService = container.resolve(ToasterService);
    }

    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands) {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.loadData();
    }

    /**
     * Get the organization data from the server
     */
    private async loadData() {
        if (this.organization) {
            const res = await this._organizationService.api.getAsync(this.organization.id);
            if (res.isOk) {
                this.organization = res.value;
            } else {
                this._toasterService.showNetworkError(res.err);
            }
        }
        this._isLoading = false;
    }

    /**
     * The save button is clicked
     * @returns 
     */
    async saveAsync() {
        const creditAmount = parseFloat(this._creditAmountEditor.liveValue);
        if (isNaN(creditAmount) || creditAmount <= 0) {
            this._toasterService.showError("Please enter a number greater than zero.");
            return;
        }

        const res = await this._organizationService.api.addCreditsAsync(
            this.organization!.id,
            creditAmount
        );
        if (res.isOk) {
            this._toasterService.showSuccess("Credits added successfully.");
            this.close(true);
        } else {
            this._toasterService.showNetworkError(res.err);
        }
    }

    /**
     * Go back to the org screen
     * @param isSave 
     */
    close(isSave: boolean) {
        this.dispatchEvent(new CustomEvent("close", { bubbles: true, composed: true, detail: { isSave } }));
    }

    render() {
        return html`
            <form id="editorForm" class="editor">
                <div class="h3">Add Credits</div>
                <se-loading-panel
                    id="loadingPanel"
                    .loadingStyle=${{
                        boxShadow: "2px 2px 2px lightGray",
                        border: "1px solid gray",
                        borderRadius: "5px 5px",
                        backgroundColor: "white",
                        minHeight: "50px",
                        minWidth: "125px",
                    }}
                    .isLoading=${this._isLoading}
                >
                    <div class="scroll-container">
                        <div class="info-row">
                            <label>Organization:</label>
                            <span>${this.organization?.name}</span>
                        </div>
                        <div class="info-row">
                            <label>Current Balance:</label>
                            <span>$${this.organization?.balance?.toFixed(2) ?? '0.00'}</span>
                        </div>
                        <se-input-editor
                            id="creditAmount"
                            name="creditAmount"
                            type="number"
                            label="Amount to Add ($)"
                            labelPosition="top"
                            input-type="number"
                            required
                            min="0"
                            step="0.01"
                            size="10"
                        ></se-input-editor>
                    </div>
                    <div class="savePanel">
                        <se-primary-button
                            .action="${() => this.saveAsync()}"
                            action-delay="500"
                            text="Add Credits"
                        ></se-primary-button>
                        <se-secondary-button @click="${() => this.close(false)}" text="Cancel"></se-secondary-button>
                    </div>
                </se-loading-panel>
            </form>
        `;
    }

    static styles = css`
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
        }
        .h3 {
            font: var(--font-h3);
            margin-bottom: 2px;
        }
        .editor {
            background-color: var(--color-light);
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0;
        }
        .scroll-container {
            height: 100%;
            min-height: 0;
            overflow: auto;
            padding: 10px;
            background-color: white;
            box-sizing: border-box;
            border-radius: 5px 5px;
            border: 1px solid gray;
            box-shadow: 2px 2px 2px lightGray;
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .info-row {
            display: flex;
            gap: 10px;
        }
        .info-row label {
            font-weight: bold;
            min-width: 120px;
        }
        .savePanel {
            display: flex;
            flex-direction: rows;
            justify-content: right;
            margin-top: 7px;
        }
    `;
}