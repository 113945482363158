import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { ConfigType } from "../../enums/config-type";
import { ConfigVersionModel } from "../../models/config-version-model";
import { AuthService } from "../../services/auth.service";
import { ConfigApi } from "../../services/config.api";
import { ConfigVersionHistoryApi } from "../../services/config-version-history.api";
import { FileUtil } from "../../services/file-util";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { ServerService } from "../../services/server.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { SeDataGrid } from "../components/data-grid.element";
import { MenuItem } from "../components/menu.element";
import { CheckboxEditorElement } from "../editors/checkbox-editor.element";

@customElement("se-config-version-history")
export class SeConfigVersionHistoryElement extends LitElement {
    @property() configId;

    private _modalService: ModalDialogService;
    private _configApi: ConfigApi;
    private _versionApi: ConfigVersionHistoryApi;
    private _userState: UserState;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _data?: ConfigVersionModel[];
    private _pageIndex = 1;
    private _recordsPerPage = 100;
    private _totalRecordCount: number;
    private _sortColumn = "";
    private _sortOrder = 1;
    private _menuService: MenuService;
    private _authService: AuthService;
    private _serverService: ServerService;

    @state() private _hasSelectedRows = false;
    @state() private _isLoading = true;

    @query("se-data-grid") private _dataGrid: SeDataGrid;
    @query("#selectAll") private _selectAll: CheckboxEditorElement;

    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._configApi = container.resolve(ConfigApi);
        this._versionApi = container.resolve(ConfigVersionHistoryApi);
        this._menuService = container.resolve(MenuService);
        this._serverService = container.resolve(ServerService);
    }

    private menu(row: ConfigVersionModel, col: DataGridColumn): MenuItem[] {
        return [
            {
                text: "Open in Editor",
                action: () =>
                    this.openEditor({
                        configId: this.configId,
                        spaceId: this._userState.selectedSpaceId,
                        ...(row.id >= 0 && { version: row.version }),
                    }),
            },
            { text: "Restore as Current Version", action: this.restoreVersionAsync.bind(this, row, col), hidden: row.id === -1 },
            { text: "Edit Comments", action: this.editCommentsAsync.bind(this, row, col), hidden: false },
            { text: "-" },
            { text: "Delete", action: this.deleteVersionAsync.bind(this, row, col), hidden: row.id === -1 },
        ];
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this.loadData();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private async deleteVersionAsync(row: ConfigVersionModel) {
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Config Version",
            body: `Are you sure you want to delete version ${row.version}.`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const result = await this._versionApi.deleteAsync(row.id);
            if (result.isOk) {
                const index = this._data.indexOf(row);
                this._data.splice(index, 1);
                if (row["selected"]) this.gridSelectionChanged();
                this._dataGrid.requestUpdate();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }
    private async restoreVersionAsync(row: ConfigVersionModel) {

        const dialogResult = await this._modalService.openConfirmWithCommentsDialogAsync({
            title: `Restore Version ${row.version}`,
            body: `Are you sure you want to make version ${row.version} the new current version. The current version will not be overwritten.`,
            comments: `Version ${row.version} restored as new current version.${row.comments ? '\n' + row.comments : '' }`,
            saveCaption: "Restore",
        });
        if (dialogResult.isSave) {
            const comments = dialogResult.value;
            const result = await this._versionApi.restoreAsync(row.id, comments);
            if (result.isOk) {
                this._toasterService.showSuccess(`Version ${row.version} restored successfully.`);
                this.refresh();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async editCommentsAsync(row: ConfigVersionModel) {
        const commentDialogResult = await this._modalService.openCommentDialogAsync({ configId: row.id, defaultComments: row.comments });

        if (commentDialogResult.isSave) {
            const comments = commentDialogResult.value;

            const result = row.id === -1 ? await this._configApi.updateCommentsAsync(this.configId, comments) 
                                         : await this._versionApi.updateCommentsAsync(row.id, comments);
            if (result.isOk) {
                this._toasterService.showSuccess(`Comments for version ${row.version} have been updated successfully.`);
                this.refresh();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    private async downloadFile(versionId: number) {
        try {
            const result = await this._versionApi.downloadVersionAsync(versionId);
            if (result.isOk) {
                const data = result.value;
                FileUtil.ViewFileAsText(data);
            } else {
                const error = result.err;
                //console.log("Error:" + error);
                this._toasterService.showNetworkError(error);
            }
        } catch (error) {
            //console.log("Error:" + error);
            this._toasterService.showNetworkError(error);
        }
    }

    private async openEditor(input: {
        configType?: ConfigType;
        draftId?: number;
        configId?: number;
        spaceId?: number;
        version?: number;
    }): Promise<boolean> {
        //get SeEditor URL from server
        let url = null;
        let editorServer = null;
        let hostApi = window.location.href;

        this._toasterService.clear();
        const response = await this._serverService.api.getAgentEditorDetailsAsync();

        if (response.isErr) {
            this._toasterService.showNetworkError(response.err);
            return false;
        } else {
            //url = "https://" + response.value.url;
            url = response.value.url;
            editorServer = response.value.editorServer;
            hostApi = response.value.hostApi !== null ? response.value.hostApi : hostApi;
        }

        const form = document.createElement("form");
        form.setAttribute("method", "POST");
        form.setAttribute("action", url);

        const params = {
            ...{
                redirectUrl: window.location.href, //callback url when editing is complete
                apiUrl: window.location.protocol + "//" + hostApi + "/api", //internal api url to use server side
                webApiUrl: window.location.origin + "/api", //external api url to use when querying control center
                token: this._authService.token, //auth token
                isInternalOrg: this._authService.user.organizationName === "Sequentum", // Hardcoded, internal organization which has special previllages
                editorServerHost: editorServer, //editor server that editor client should connect to
                orgId: this._authService.user.organizationId,
                isProxyRequired: this._authService.orgSettings.isProxyRequired,
                isDevTools: this._authService.orgSettings.isAllowDevTools,
            },
            ...input,
        };
        for (const key in params) {
            if (Object.prototype.hasOwnProperty.call(params, key)) {
                const hiddenField = document.createElement("input");
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", key);
                hiddenField.setAttribute("value", params[key]);
                form.appendChild(hiddenField);
            }
        }
        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
        return true;
    }

    private async loadData() {
        this._columns = [
            {
                field: "version",
                title: "Version",
                align: "center",
                sortable: true,
                htmlTitle: "Open version in editor",
                template: (row) => {
                    return row.id >= 0 ? html`v${row.version}` : html`Current (v${row.version})`;
                },
                actionLink: (row) =>
                    this.openEditor({
                        configId: this.configId,
                        spaceId: this._userState.selectedSpaceId,
                        ...(row.id >= 0 && { version: row.version }),
                    }),
            },
            {
                field: "comments",
                title: "Comments",
                template: (row) => {
                    const comments = row.comments?.trim()?.replaceAll("\n-\n", "<hr style='margin: 2px 0'/>").replaceAll("\n", "<br/>");
                    return html`${unsafeHTML(comments)}`;
                },
            },
            { field: "userName", title: "User", sortable: true },
            {
                field: "fileSize",
                title: "Size",
                template: (row) => {
                    const sizeKb = Math.ceil(row.fileSize / 1024);
                    return html`${sizeKb}KB`;
                },
            },
            {
                field: "created",
                title: "Added",
                align: "center",
                sortable: true,
                template: (row) => {
                    const date = row.created ? new Date(row.created) : undefined;
                    return date ? html`${date.toLocaleDateString()} ${date.toLocaleTimeString()}` : html``;
                },
            },
            { name: "menu", cellStyle: { textAlign: "center", width: "20px" }, menu: (row, col) => this.menu(row, col) },
        ];

        this._isLoading = true;
        try {
            const result = await this._versionApi.getManyAsync(this.configId);
            if (result.isOk) {
                this._data = result.value;
            } else {
                this._toasterService.showUnexpectedError(result.err.message);
            }
        } finally {
            this._isLoading = false;
        }
        if (this._selectAll) this._selectAll.value = false;
    }

    private selectAll(evt: Event) {
        if (evt.target instanceof CheckboxEditorElement) {
            if (evt.target.liveValue) {
                this._dataGrid.selectAllRows();
                this._hasSelectedRows = true;
            } else {
                this._dataGrid.clearSelection();
                this._hasSelectedRows = false;
            }
        }
    }

    private onGridSelectionChanged(evt: Event) {
        evt.stopPropagation();
        this.gridSelectionChanged();
    }

    private gridSelectionChanged() {
        if (this._dataGrid.selectedRows.length === 0) {
            this._selectAll.value = false;
            this._hasSelectedRows = false;
        } else if (this._dataGrid.selectedRows.length !== this._data.length) {
            this._selectAll.value = undefined;
            this._hasSelectedRows = true;
        } else {
            this._selectAll.value = true;
            this._hasSelectedRows = true;
        }
    }

    private sortDataGrid(evt: CustomEvent) {
        evt.stopPropagation();
        const sortColumn = evt.detail.sortColumn;
        const sortOrder = evt.detail.sortOrder;

        this._sortColumn = sortColumn;
        this._sortOrder = sortOrder;

        this.loadData();
    }

    private onPageChanged(evt: CustomEvent) {
        evt.stopPropagation();
        this._pageIndex = evt.detail.pageIndex;
        this._dataGrid.pageIndex = this._pageIndex;
        this.loadData();
    }

    private refresh() {
        this.loadData();
    }

    private async onDeleteMany(event: MouseEvent) {
        event.stopPropagation();

        const count = this._dataGrid.selectedRows.length;
        const result = await this._modalService.openConfirmDialogAsync({
            title: "Delete Config Versions",
            body: `Are you sure you want to delete ${count === 1 ? `1 version` : `${count} versions`}?`,
            saveCaption: "Delete",
        });
        if (result.isSave) {
            const ids = this._dataGrid.selectedRows.map((p) => p.id as number);
            const result = await this._versionApi.deleteManyAsync(this.configId, ids);
            if (result.isOk) {
                this._selectAll.value = false;
                this._hasSelectedRows = false;
                this.loadData();
            } else if (result.isErr) {
                this._toasterService.showNetworkError(result.err);
            }
        }
    }

    render() {
        const selectTitle = this._hasSelectedRows ? "Clear Selection" : "Select All";
        return html`
            <div class="body">
                <div class="header">
                    <div class="left-header">
                        <se-checkbox-editor
                            style="margin-right:5px"
                            id="selectAll"
                            tristate="auto"
                            @valueChanged=${this.selectAll}
                            ${htmlTitle(selectTitle)}
                            .value=${false}
                            .disabled=${(this._data?.length ?? 0) === 0}
                        ></se-checkbox-editor>

                        ${this._hasSelectedRows
                            ? html`
                                  <se-secondary-button
                                      @mousedown=${(event) => this.onDeleteMany(event)}
                                      ${htmlTitle("Delete selected versions")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-trash-alt"
                                  ></se-secondary-button>
                              `
                            : html`
                                  <se-secondary-button
                                      @click=${this.refresh}
                                      ${htmlTitle("Refresh")}
                                      .customStyle=${{ padding: "1px 6px", margin: 0 }}
                                      icon="far fa-redo"
                                  ></se-secondary-button>
                              `}
                    </div>
                </div>
                <se-data-grid
                    class="grid"
                    .rows=${this._data}
                    .columns=${this._columns}
                    selectable
                    @selectionchanged=${this.onGridSelectionChanged}
                    @sortdata=${this.sortDataGrid}
                    placeholder="No versions available."
                    .isLoading=${this._isLoading}
                ></se-data-grid>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
        .header {
            margin-left: 5px;
            display: flex;
            align-items: end;
            justify-content: space-between;
            overflow: hidden;
            padding-right: 5px;
            margin-right: -5px;
            padding-bottom: 5px;
            margin-bottom: -5px;
        }
        .left-header {
            display: flex;
            align-items: center;
            gap: 5px;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
    `;
}
