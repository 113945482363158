import { LitElement, html, css, unsafeCSS} from "lit";
import { customElement, query } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router } from "@vaadin/router";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import { InputEditorElement } from "../../elements/editors/input-editor.element";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import logo from "../../../../assets/logo-tagline.svg";

@customElement("se-complete-verification")
export class SeCompleteVerificationElement extends LitElement {
    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .loginButton {
            margin-top: 10px;
        }
        .inputEditor {
            width: 100%;
        }
    `;
    @query("#name") private _nameEditor: InputEditorElement;
    @query("#password") private _passwordEditor: InputEditorElement;
    @query("#confirmPassword") private _confirmPasswordEditor: InputEditorElement;

    private _userService = container.resolve(UserService);
    private _toasterService = container.resolve(ToasterService);
    private _token: string;

    connectedCallback() {
        super.connectedCallback();
        const params = new URLSearchParams(window.location.search);
        this._token = params.get('token');
        
        if (!this._token) {
            Router.go('/login');
        }
    }

    render() {
        return html`
            <form id="verificationForm" class="editor">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <se-input-editor
                            id="name"
                            name="name"
                            type="text"
                            label="Full Name"
                            labelPosition="top"
                            required
                            class="inputEditor"
                            size="normal"
                        ></se-input-editor>

                        <se-input-editor
                            id="password"
                            name="password"
                            type="password"
                            input-type="password"
                            label="Password"
                            labelPosition="top"
                            required
                            class="inputEditor"
                            size="normal"
                        ></se-input-editor>

                        <se-input-editor
                            id="confirmPassword"
                            name="confirmPassword"
                            type="password"
                            input-type="password"
                            label="Confirm Password"
                            labelPosition="top"
                            required
                            class="inputEditor"
                            size="normal"
                        ></se-input-editor>

                        <se-primary-button
                            .action="${() => this.completeVerification()}"
                            action-delay="500"
                            text="Complete Registration"
                            class="inputEditor loginButton"
                            size="normal"
                        ></se-primary-button>
                    </div>
                </div>
            </form>
        `;
    }

    private async completeVerification() {
        if (!this.reportValidity()) return;
        
        if (this._passwordEditor.liveValue !== this._confirmPasswordEditor.liveValue) {
            this._toasterService.showError("Passwords do not match");
            return;
        }

        try {
            const result = await this._userService.api.completeVerificationAsync({
                token: this._token,
                name: this._nameEditor.liveValue,
                password: this._passwordEditor.liveValue
            });

            if (result.isOk) {
                this._toasterService.showSuccess("Registration completed successfully");
                Router.go('/login');
            } else {
                this._toasterService.showError(result.err.message || "Failed to complete registration");
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
        }
    }

    private reportValidity(): boolean {
        for (const elem of Array.from(this.shadowRoot.querySelectorAll("*"))) {
            if ((elem as any)?.reportValidity?.() === false) return false;
        }
        return true;
    }
} 