import { container, singleton } from "tsyringe";
import { NetworkResult, ok } from "se-shared/utils/result";
import { ConfigFileType } from "se-shared/enums/config-file-type";
import { ConfigType } from "../enums/config-type";
import { ValidationStatus } from "../enums/validation-status";
import { ConfigInfoModel } from "../models/config-info-model";
import { ConfigRunPageModel } from "../models/config-run-model";
import { ConfigsSummaryViewModel } from "../models/configs-summary-view-model";
import { ConfigDashboardViewModel } from "../models/config-dashboard-view-model";
import { idName } from "../models/id-name";
import { AuthService } from "./auth.service";
import { BaseApi } from "./base.api";
import { ConfigSearchResultModel } from "../models/config-search-result";
import { ConfigEmailAlertModel } from "../models/config-email-alert-model";

@singleton()
export class ConfigApi extends BaseApi {
    private _authService: AuthService;

    constructor() {
        super();

        this._authService = container.resolve(AuthService);
    }

    async getSpacesSummariesAsync() {
        return this.getObjectAsync<{
            spaceSummaries: {
                SpaceName: string;
                SpaceId: number;
                stats: {
                    successCount: number;
                    failCount: number;
                    progressCount: number;
                    notRunCount: number;
                };
            }[];
            stats: {
                successCount: number;
                failCount: number;
                progressCount: number;
                notRunCount: number;
            };
            agentsCount: number;
            filesCount: number;
            templatesCount: number;
            last2WeeksByDay: {
                Date: Date;
                PassCount: number;
                FailCount: number;
            }[];
            recentRunningAgents: ConfigDashboardViewModel[];
            recentUpdatedAgents: ConfigDashboardViewModel[];
            recentFailedAgents: ConfigDashboardViewModel[];
        }>(`/api/config/dashboard-summary-data`, this._authService.token);
    }

    async getRunPageModelAsync(configId: number): Promise<NetworkResult<ConfigRunPageModel>> {
        const configs = await this.getObjectAsync<ConfigRunPageModel>(`/api/config/${configId}/run-page`, this._authService.token);
        if (configs.isOk) {
            return ok(configs.value);
        } else {
            return configs;
        }
    }

    async getRunableIdsByLabelAsync(spaceId: number, labelId: number): Promise<NetworkResult<{ ids: number[] }>> {
        const configs = await this.getObjectAsync<{ ids: number[] }>(
            `/api/config/space/${spaceId}/label/${labelId}/runable`,
            this._authService.token
        );
        if (configs.isOk) {
            return ok(configs.value);
        } else {
            return configs;
        }
    }
    async getRunableIdsByTemplateAsync(spaceId: number, templateId: number): Promise<NetworkResult<{ ids: number[] }>> {
        const configs = await this.getObjectAsync<{ ids: number[] }>(
            `/api/config/space/${spaceId}/template/${templateId}/runable`,
            this._authService.token
        );
        if (configs.isOk) {
            return ok(configs.value);
        } else {
            return configs;
        }
    }

    async getSearchResultAsync(
        query: string,
        configTypes: number[],
        statuses: number[]
    ): Promise<NetworkResult<ConfigSearchResultModel[]>> {
        const type = configTypes.map((m) => "type=" + m).join("&");
        const status = statuses.map((m) => "status=" + m).join("&");
        const configs = await this.getObjectAsync<ConfigSearchResultModel[]>(
            `/api/config/search?${query ? "query=" + query : ""}${type ? "&" + type : ""}${status ? "&" + status : ""}`,
            this._authService.token
        );
        if (configs.isOk) {
            return ok(configs.value);
        } else {
            return configs;
        }
    }

    async getManyByLabelAsync(
        spaceId: number,
        labelId: number,
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1,
        strFilters = ""
    ): Promise<NetworkResult<ConfigsSummaryViewModel>> {
        const configs = await this.getObjectAsync<ConfigsSummaryViewModel>(
            `/api/config/space/${spaceId}/label/${labelId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&filters=${strFilters}`,
            this._authService.token
        );
        if (configs.isOk) {
            this.fixLabels(configs.value);
            return ok(configs.value);
        } else {
            return configs;
        }
    }
    async getManyByAgentTemplateAsync(
        spaceId: number,
        agentTemplateId: number,
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1,
        strFilters: string
    ): Promise<NetworkResult<ConfigsSummaryViewModel>> {
        const configs = await this.getObjectAsync<ConfigsSummaryViewModel>(
            `/api/config/space/${spaceId}/agent-template/${agentTemplateId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&filters=${strFilters}`,
            this._authService.token
        );
        if (configs.isOk) {
            this.fixLabels(configs.value);
            return ok(configs.value);
        } else {
            return configs;
        }
    }
    async getManyByStatusAsync(
        spaceId: number,
        agentTemplateId: number,
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1,
        strFilters: string
    ): Promise<NetworkResult<ConfigsSummaryViewModel>> {
        const configs = await this.getObjectAsync<ConfigsSummaryViewModel>(
            `/api/config/space/${spaceId}/status/${agentTemplateId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&filters=${strFilters}`,
            this._authService.token
        );
        if (configs.isOk) {
            this.fixLabels(configs.value);
            return ok(configs.value);
        } else {
            return configs;
        }
    }
    async getManyBySharedFileAsync(
        spaceId: number,
        sharedFileId: number,
        pageIndex = 1,
        recordsPerPage = 100,
        sortColumn = "",
        sortOrder = -1,
        strFilters: string
    ): Promise<NetworkResult<ConfigsSummaryViewModel>> {
        const configs = await this.getObjectAsync<ConfigsSummaryViewModel>(
            `/api/config/space/${spaceId}/agent-shared-file/${sharedFileId}?pageIndex=${pageIndex}&recordsPerPage=${recordsPerPage}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&filters=${strFilters}`,
            this._authService.token
        );
        if (configs.isOk) {
            this.fixLabels(configs.value);
            return ok(configs.value);
        } else {
            return configs;
        }
    }
    async getChartDataAsync(type: string, spaceId: number, strFilters: string, filterId?: number): Promise<NetworkResult<object[]>> {
        const configs = await this.getObjectAsync<object[]>(
            `/api/config/space/${spaceId}/charts-data/${type}?filterId=${filterId}&filters=${strFilters}`,
            this._authService.token
        );
        if (configs.isOk) {
            return ok(configs.value);
        } else {
            return configs;
        }
    }

    private fixLabels(configs: ConfigsSummaryViewModel) {
        configs.configSummaries.map((p) => {
            p.labels = p.labels
                ? Object.entries(JSON.parse(p.labels as any) as { [id: string]: string })
                      .map(([key, value]) => ({
                          id: parseInt(key),
                          name: value,
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))
                : [];

            p.agentTemplates = p.agentTemplates
                ? Object.entries(JSON.parse(p.agentTemplates as any) as { [id: string]: string | any[] })
                      .map(([key, value]) =>
                          Array.isArray(value)
                              ? {
                                    id: parseInt(key),
                                    name: value[0],
                                    sort: value[1],
                                }
                              : {
                                    //Backward compatibillity
                                    id: parseInt(key),
                                    name: value,
                                    sort: 1,
                                }
                      )
                      .sort()
                : [];

            if (p.configType === ConfigType.Template) {
                p.agentTemplates.push({ name: p.name, id: p.id, sort: 0 });
            }

            p.sharedFiles = p.sharedFiles
                ? Object.entries(JSON.parse(p.sharedFiles as any) as { [id: string]: string })
                      .map(([key, value]) => ({
                          id: parseInt(key),
                          name: value,
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))
                : [];

            if (p.configType === ConfigType.SharedFile) {
                p.sharedFiles.push({ name: p.name, id: p.id });
            }

            return p;
        });
    }

    async deleteAllAsync(configIds: number[]) {
        return this.deleteAllObjectsAsync(`/api/config/all`, { ids: configIds }, this._authService.token);
    }
    async deleteAsync(configId: number) {
        return this.deleteObjectAsync(`/api/config/${configId}`, this._authService.token);
    }
    async deleteAllRunsAsync(configId: number) {
        return this.deleteObjectAsync(`/api/config/${configId}/runs/all`, this._authService.token);
    }
    async moveToSpaceAsync(newSpaceId: number, configIds: number[]) {
        return this.postObjectAsync(`/api/config/move-to-space/${newSpaceId}`, { ids: configIds }, this._authService.token);
    }
    async copyToSpaceAsync(spaceId: number, configIds: number[]) {
        return this.postObjectAsync(`/api/config/copy-to-space/${spaceId}`, { ids: configIds }, this._authService.token);
    }
    async copyAsync(configId: number, name: string) {
        return this.postObjectAsync(`/api/config/copy/${configId}`, { name: name }, this._authService.token);
    }
    async renameAsync(configId: number, newName: string) {
        return this.postObjectAsync(`/api/config/${configId}/rename`, { content: newName }, this._authService.token);
    }
    async addLabelAsync(configId: number, labelId: number) {
        return this.postObjectAsync(`/api/config/${configId}/label/${labelId}`, {}, this._authService.token);
    }
    async removeLabelAsync(configId: number, labelId: number) {
        return this.deleteObjectAsync(`/api/config/${configId}/label/${labelId}`, this._authService.token);
    }
    async addSharedFileAsync(configId: number, sharedFileId: number) {
        return this.postObjectAsync(`/api/config/${configId}/shared-file/${sharedFileId}`, {}, this._authService.token);
    }
    async removeSharedFileAsync(configId: number, sharedFileId: number) {
        return this.deleteObjectAsync(`/api/config/${configId}/shared-file/${sharedFileId}`, this._authService.token);
    }
    async getAvailableAgentTemplatesAsync(spaceId: number) {
        return this.getObjectAsync<idName[]>(`/api/config/agent-templates/available/space/${spaceId}`, this._authService.token);
    }
    async getLocalSharedFilesAsync(spaceId: number) {
        return this.getObjectAsync<idName[]>(`/api/config/shared-files/local/space/${spaceId}`, this._authService.token);
    }
    async getConfigSharedFileIdsAsync(configId: number) {
        return this.getObjectAsync<number[]>(`/api/config/${configId}/shared-files`, this._authService.token);
    }
    async getConfigLabelIdsAsync(configId: number) {
        return this.getObjectAsync<number[]>(`/api/config/${configId}/labels`, this._authService.token);
    }
    async getConfigAgentTemplateIdsAsync(configId: number) {
        return this.getObjectAsync<number[]>(`/api/config/${configId}/agent-templates`, this._authService.token);
    }
    async getConfigInfo(configId: number) {
        return this.getObjectAsync<ConfigInfoModel>(`/api/config/${configId}/info`, this._authService.token);
    }
    async getConfigSpaceName(configId: number) {
        return this.getObjectAsync<{ id: number; name: string }>(`/api/config/${configId}/space-name`, this._authService.token);
    }
    async hasAllProxies(configIds: number[]) {
        return this.postObjectReturnObjectAsync<{ hasProxies: boolean }>(
            `/api/config/has-proxies`,
            { ids: configIds },
            this._authService.token
        );
    }
    async getNameAndTypeAsync(configId: number) {
        return this.getObjectAsync<{
            name: string;
            type: ConfigType;
            validationStatus: ValidationStatus;
            spaceName?: string;
            spaceId?: number;
            message?: string;
            organizationId?: number;
        }>(`/api/config/${configId}/name`, this._authService.token);
    }
    async getSharedFileDetailsAsync(configId: number) {
        return this.getObjectAsync<{ name: string; fileType: ConfigFileType; fileSize: number }>(
            `/api/config/${configId}/shared-file-info`,
            this._authService.token
        );
    }
    async updateDescriptionAsync(configId: number, description: string) {
        return this.postObjectAsync(`/api/config/${configId}/description`, { content: description }, this._authService.token);
    }

    async updateCommentsAsync(configId: number, comments: string) {
        return this.postObjectAsync(`/api/config/${configId}/comments`, { content: comments }, this._authService.token);
    }

    async updateSharedFileAsync(configId: number, text: string, name: string) {
        return this.postObjectAsync(`/api/config/${configId}/updatetext?name=${name}`, { content: text }, this._authService.token);
    }

    async updateDocumentationAsync(configId: number, documentation: string) {
        return this.postObjectAsync(`/api/config/${configId}/documentation`, { content: documentation }, this._authService.token);
    }
    async updateIconAsync(configId: number, icon: string) {
        return this.postObjectAsync(`/api/config/${configId}/icon`, { content: icon }, this._authService.token);
    }
    async validateAllAsync(configIds: number[]) {
        return this.postObjectReturnObjectAsync<{ configId: number; validationStatus: ValidationStatus }[]>(
            `/api/config/validate/many`,
            { ids: configIds },
            this._authService.token
        );
    }
    async resetExportTrackingAsync(configId: number) {
        return this.postObjectAsync(`/api/config/${configId}/tracking/reset`, { configId: configId }, this._authService.token);
    }

    async resetSuccessCriterisAsync(configId: number) {
        return this.postObjectAsync(`/api/config/${configId}/success-criteria/reset`, { configId: configId }, this._authService.token);
    }

    async downloadTracking(configId: number) {
        const response = await this.postObjectReturnObjectAsync<{ downloadUrl: string }>(
            `/api/config/${configId}/tracking/download/authorize`,
            null,
            this._authService.token
        );

        if (response.isOk) {
            const downloadUrl = response.value.downloadUrl;
            if (downloadUrl != null) {
                location.href = downloadUrl;
                //window.open(downloadUrl, '_blank');
            }
        }
    }

    async getConfigEmailAlertAsync(configId: number) {
        return this.getObjectAsync<ConfigEmailAlertModel>(`/api/config/alerts/${configId}`, this._authService.token);
    }
    async updateConfigEmailAlertAsync(alert: ConfigEmailAlertModel) {
        return this.postObjectAsync(`/api/config/alerts/${alert.configId}`, alert, this._authService.token);
    }
}
