import "element-internals-polyfill";
import { css, html, LitElement } from "lit";
import { customElement, property, query, state } from "lit/decorators.js";
import { styleMap } from "lit/directives/style-map.js";
import { BaseEditor } from "./base-editor";

@customElement("se-checkbox-editor")
export class CheckboxEditorElement extends LitElement implements BaseEditor {
    @property({ type: Boolean }) value = false;
    oldValue?: boolean = false;
    @state() liveValue?: boolean = false;

    @property() tristate?: "never" | "always" | "auto";

    @property() label?: string;
    @property() labelPosition?: "left" | "top" | "right" | "bottom" = "top";

    @property() alignItems?: "start" | "center" | "end" = "center";

    @property({ type: Boolean }) readonly?: boolean;
    @property({ type: Boolean }) disabled = false;
    @property() name: string;
    @property({ attribute: "editor-size" }) editorSize: "small" | "nomal";

    @property({ type: Boolean }) isSettingsStyle = false;

    @query("#editor") private _editorElement: HTMLDivElement;

    willUpdate(changedProperties) {
        if (changedProperties.has("value")) {
            this.oldValue = this.value;
            this.liveValue = this.value;
            if (this.tristate === undefined && this.value === undefined) {
                this.tristate = "always";
            }
        }
    }

    reportValidity() {
        return true;
    }

    disable(isDisable = true) {
        this.disabled = isDisable;
    }

    cancel() {
        this.liveValue = this.value;
        this.requestUpdate();
    }

    onMouseUp() {
        if (this.disabled || this.readonly !== undefined) return;

        switch (this.liveValue) {
            case undefined:
                if (this.tristate === "auto") this.liveValue = false;
                else this.liveValue = true;
                break;
            case true:
                this.liveValue = false;
                break;
            case false:
                if (this.tristate === "always") this.liveValue = undefined;
                else this.liveValue = true;
                break;
        }
        this.dispatchEvent(
            new CustomEvent("valueChanged", {
                bubbles: true,
                composed: true,
                detail: { editor: this, value: this.liveValue, oldValue: this.value },
            })
        );
        this.dispatchEvent(new CustomEvent("editorChanged", { bubbles: true, composed: true, detail: { editor: this } }));
    }

    render() {
        const iconClass =
            this.liveValue === undefined && (this.tristate === "always" || this.tristate === "auto")
                ? "fa-minus-square"
                : this.liveValue === true
                  ? "fa-check-square"
                  : "fa-square";
        const iconStyle =
            this.liveValue || (this.tristate === "always" && this.liveValue === undefined) ? { color: "black" } : { color: "gray" };
        const flexDirection =
            (this.labelPosition === "left" && "row-reverse") ||
            (this.labelPosition === "top" && "column-reverse") ||
            (this.labelPosition === "right" && "row") ||
            (this.labelPosition === "bottom" && "column");
        const justifyContent = this.labelPosition === "left" ? "space-between" : "flex-start";
        const gap =
            (this.labelPosition === "left" && "5px") ||
            (this.labelPosition === "top" && "0px") ||
            (this.labelPosition === "right" && "5px") ||
            (this.labelPosition === "bottom" && "0px");

        const style = this.label ? { display: "flex", flexDirection: flexDirection, justifyContent: justifyContent, gap: gap, alignItems: this.alignItems } : {};

        const font = { font: this.editorSize === "small" ? "var(--font-input-small)" : "var(--font-input)" };
        if (this.disabled) {
            font["color"] = "gray";
            iconStyle["color"] = "gray";
        }

        return html`
            <div id="editor" style="${styleMap({ ...style, ...font })}" @mouseup=${this.onMouseUp}>
                <fa-icon style=${styleMap(iconStyle)} class="icon" scale="1.2" fa-class="far ${iconClass}"></fa-icon>
                <label for="input">${this.label}</label>
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
            font: var(--font);
            line-height: 1.5em;
        }
        :host([hidden]) {
            display: none;
        }
        .icon {
            color: gray;
        }
        .icon:hover {
            color: black;
        }
        label {
            font: var(--font-input);
        }
        input:disabled + label {
            color: gray;
        }
    `;
}
