import { PreventAndRedirectCommands, RedirectResult, Router, RouterLocation } from "@vaadin/router";
import { css, html, LitElement } from "lit";
import { customElement, query, state } from "lit/decorators.js";
import { choose } from "lit/directives/choose.js";
import { classMap } from "lit/directives/class-map.js";
import { htmlTitle } from "se-shared/directives/html-title.directive";
import { ToasterService } from "se-shared/services/toaster.service";
import { container } from "tsyringe";
import { AccessLevel } from "../../enums/access-level";
import { AuthService } from "../../services/auth.service";
import { MenuService } from "../../services/menu.service";
import { ModalDialogService } from "../../services/modal-editor.service";
import { OrganizationApi } from "../../services/organization.api";
import { UserService } from "../../services/user.service";
import { UserState } from "../../services/user.state";
import { DataGridColumn } from "../components/data-grid-template";
import { AppSettings } from "../../models/app-settings";
import "../billing/transactions.element";
import "../components/side-nav.element";

@customElement("se-admin-settings")
export class SeAdminSettingsElement extends LitElement {
    private _menuService: MenuService;
    private _modalService: ModalDialogService;
    private _authService: AuthService;
    private _userState: UserState;
    private _settings: AppSettings;
    private _userService: UserService;
    private _organizationService: OrganizationApi;
    //private _labelService: LabelService;
    //private _spaceService: SpaceService;
    private _toasterService: ToasterService;
    private _columns: DataGridColumn[] = [];
    @state() private _menuItems = [];


    constructor() {
        super();
        this._authService = container.resolve(AuthService);
        this._toasterService = container.resolve(ToasterService);
        this._userState = container.resolve(UserState);
        this._modalService = container.resolve(ModalDialogService);
        this._menuService = container.resolve(MenuService);
        this._settings = container.resolve(AppSettings);

        //       this._labelService = container.resolve(LabelService);
        //       this._spaceService = container.resolve(SpaceService);
        this._organizationService = container.resolve(OrganizationApi);
        this._userService = container.resolve(UserService);
    }

    connectedCallback() {
        super.connectedCallback();
        this._userState.selectedSpaceId = -6;
        this._userState.selectedLabelId = -1;
        this._userState.selectedSpaceOrLabelChanged.triggerVoid();
        this._initializeMenuItems();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }

    private _initializeMenuItems() {
        this._menuItems = [
            { label: "Servers", path: "servers" },
            { label: "Organizations", path: "organizations" },
            { label: "All Runs", path: "all-runs" },
            { label: "System Audit", path: "system-logs" },
            { label: "Rate Limits", path: "rate-limits" },
            { label: "App Settings", path: "app-settings" },
            { label: "Server Keys", path: "server-keys" }
        ];
    }

    public onBeforeEnter(
        location: RouterLocation,
        commands: PreventAndRedirectCommands,
        router: Router
    ): Promise<unknown> | RedirectResult | undefined {
        if (!this._authService.isSE4Admin) {
            return commands.redirect("/login");
        }
    }
    
    render() {
        return html`
            <div class="body">
                <div class="top-bar">
                    <div class="breadcrumbs">
                        <a>Manage</a>
                        <span>/</span>
                        <a>Admin</a>
                    </div>
                </div>
                <se-nav 
                    baseUrl="/manage/admin" 
                    .menuItems=${this._menuItems}
                >
                    <slot></slot>
                </se-nav>
            </div>
        `;
    }
    
    static styles = css`
        :host {
            display: block;
            box-sizing: border-box;
            font: var(--font);
            height: 100%;
        }
        .body {
            height: 100vh;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
        h1 {
            margin: 0px;
            font-weight: 600;
        }
        .top-bar {
            background-color: var(--color-blue-25);
            padding: 15px;
            border-bottom: solid 1px var(--color-navy-3);
            width: 100%;
        }
        .breadcrumbs {
            display: flex;
            flex-direction: row;
            gap: 10px;
            font: var(--font-smaller);
        }
        .breadcrumbs a{
            color: var(--color-primary);
            text-decoration: none;
        }
        .breadcrumbs a:hover{
            text-decoration: underline;
        }
        .grid {
            flex: 1;
        }
        .checkbox {
            width: 1rem;
            height: 1rem;
        }
        input[type="checkbox"]:checked {
            background-color: var(--color-secondary);
        }
        .label {
            background-color: dimgray;
            border-radius: 3px 3px;
            font: var(--font-smaller);
        }
        .tabs-header{
            display:flex;
            gap: 10px;
            background-color: var(--color-light);
            border-bottom: solid 1px var(--color-gray-2);
        }
        .active-tab{
            color: var(--color-purple);
        }
        .active-tab::before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            height: 3px;
            border-radius: 9px;
            background-color: var(--color-purple);
        }
        .tabs{
            cursor:pointer;
            padding: 10px;
            position: relative;
        }
        .tabs:hover{
            background-color: rgb(49 54 93 / 9%);
            border-radius: 8px 8px 0 0;
        }
        .container-content{
            overflow-y: auto;
            overflow-x: hidden;
            height: 100%;
        }
    `;
}
