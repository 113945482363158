import { css, html, LitElement } from "lit";
import { customElement, query } from "lit/decorators.js";
import { until } from "lit/directives/until.js";
import "reflect-metadata";
import { container } from "tsyringe";
import "./control-center/elements/control-center.element";
import { ControlCenterElement } from "./control-center/elements/control-center.element";
import { AppConfigService } from "./control-center/services/app-config.service";
import { MenuService } from "./control-center/services/menu.service";

@customElement("se-app")
export class SeApp extends LitElement {
    private _appConfigService: AppConfigService;
    private _menuService: MenuService;

    constructor() {
        super();
        this._appConfigService = container.resolve(AppConfigService);
        this._menuService = container.resolve(MenuService);
    }

    //static theMode: ShadowRootMode = 'closed';
    //static shadowRootOptions = { mode: SeApp.theMode};

    sessionId: string;

    @query("#controlCenter") controlCenter: ControlCenterElement;

    static styles = css`
        :host {
            display: none;
            box-sizing: border-box;
            height: 100%;
            width: 100%;
            margin: 0;
            padding: 0;
        }
    `;

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener("blur", this.closeMenu);
        this.addEventListener("mousedown", this.closeMenu);
        this.addEventListener("menuopening", this.closeMenu);
    }

    closeMenu(e: Event) {
        if (this._menuService) this._menuService.closeMenu();
    }

    private onStopLoading(isSuccess, msg?: string) {
        this.dispatchEvent(new CustomEvent("stoploading", { bubbles: true, composed: true, detail: { isSuccess: isSuccess, msg: msg } }));
    }

    render() {
        return html`
            ${until(
                Promise.all([this._appConfigService.initializeAsync()])
                    .then(() => {
                        this.onStopLoading(true);
                        return html`<se-control-center id="controlCenter"></se-control-center>`;
                    })
                    .catch((err) => this.onStopLoading(false, err?.message)),
                html``
            )}
        `;
    }
}
