import { LitElement, html, css, unsafeCSS } from "lit";
import { customElement, state } from "lit/decorators.js";
import { container } from "tsyringe";
import { Router, RouterLocation, PreventAndRedirectCommands } from "@vaadin/router";
import { UserService } from "../../services/user.service";
import { ToasterService } from "se-shared/services/toaster.service";
import backgroundMaze from "../../../../assets/backgroundMaze.png";
import logo from "../../../../assets/logo-tagline.svg";
import instagram from "../../../../assets/instagram.svg";
import linkedin from "../../../../assets/linkedin.svg";
import twitter from "../../../../assets/twitter.svg";

@customElement("se-email-verification")
export class SeEmailVerificationElement extends LitElement {
    private _userService = container.resolve(UserService);
    private _toasterService = container.resolve(ToasterService);
    private _token: string;
    @state() private _verifying = true;


    async onBeforeEnter(location: RouterLocation, commands: PreventAndRedirectCommands, router: Router) {
        if (location.params.token) {
            this._token = location.params.token.valueOf() as string;
        } else {
            return commands.redirect("/login");
        }
    }

    connectedCallback() {
        super.connectedCallback();
        this.verifyEmail();
    }

    private async verifyEmail() {
        try {
            const result = await this._userService.api.verifyEmailAsync(this._token);
            if (result.isOk) {
                this._verifying = false;
                this.requestUpdate();
            } else {
                this._toasterService.showError(result.err.message || "Failed to verify email");
                Router.go('/login');
            }
        } catch (err) {
            this._toasterService.showNetworkError(err);
            Router.go('/login');
        }
    }

    private goToLogin(ev: Event) {
        ev.preventDefault();
        Router.go('/login');
    }

    render() {
        return html`
            <form id="verificationForm" class="editor">
                <div class="scroll-container">
                    <div class="content">
                        <div style="text-align: center;margin-bottom: 10px;">
                            <img src=${logo} class="header-logo" />
                        </div>
                        <div class="h2">
                            ${this._verifying ? 'Verifying Email...' : 'Email Verified Successfully'}
                        </div>
                        <div class="message">
                            ${this._verifying 
                                ? 'Please wait while we verify your email address.'
                                : 'Your email has been verified. You can now log in to your account.'}
                        </div>
                        ${!this._verifying ? html`
                            <se-primary-button
                                size="normal"
                                position="first"
                                @click="${this.goToLogin}"
                                text="Go to Login"
                                class="inputEditor loginButton"
                            ></se-primary-button>
                        ` : ''}
                    </div>
                </div>
            </form>
        `;
    }
    static styles = css`
        :host([hidden]) {
            display: none;
        }
        :host {
            display: flex;
            flex-direction: column;
            height: 100%;
            font-family: Inter;
            background-image: ${unsafeCSS(`url(${backgroundMaze})`)};
            background-size: cover;
        }
        :host * {
            box-sizing: border-box;
        }
        .header-logo {
            width: 165px;
            height: fit-content;
            margin: 27px;
        }
        .editor {
            display: flex;
            flex-direction: column;
            margin: auto;
            width: fit-content;
            min-height: 0px;
            padding-top: 0px;
            border-radius: 5px;
        }
        .scroll-container {
            min-height: 0px;
            overflow: auto;
            background-color: white;
            box-sizing: border-box;
            border-radius: 24px;
            padding: 40px 90px;
        }
        .content {
            width: 300px;
            gap: 10px;
            margin: auto;
            display: flex;
            flex-direction: column;
        }
        .loginButton {
            margin-top: 10px;
        }
        .inputEditor {
            width: 100%;
        }
        .h2 {
            font: var(--font-h2);
            color: var(--color-primary);
            font-weight: 600;
            padding: 0px 0px 10px;
        }
        .message {
            margin: 20px 0;
        }
    `;

}